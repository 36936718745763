









































import Vue from "vue";
import { mapActions } from "vuex";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { AUTH } from "../../routes/endpoints";

export default Vue.extend({
    data() {
        return {
            loading: false,
            form: { password: "", confirmPassword: "" },
            AUTH,
            isPassword1Visible: false,
            isPassword2Visible: false,
        };
    },
    mounted() {
        const { Token, Email } = this.$route.query;
        if (typeof Token === "undefined" || typeof Email === "undefined") {
            this.$router.replace({ name: AUTH.LOGIN.NAME });
        }
    },
    methods: {
        ...mapActions("auth", ["passwordReset"]),
        async resetPassword() {
            try {
                this.loading = true;
                const { Token, Email } = this.$route.query;

                const payload = { token: Token, email: Email, password: this.form.password, confirmPassword: this.form.confirmPassword };

                const res = await this.passwordReset(payload);

                if (res.status === 200) {
                    this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });
                    setTimeout(() => {
                        this.$router.replace({ name: AUTH.LOGIN.NAME });
                    }, 1000);
                } else {
                    this.$bvToast.toast("We could not reset your password", { title: "Something went wrong", variant: "danger", solid: true });
                }

                this.loading = false;
            } catch (error: unknown) {
                this.loading = false;
            }
        },
    },
    validations() {
        return {
            form: {
                password: { required, minLength: minLength(8) },
                confirmPassword: {
                    required,
                    minLength: minLength(8),
                    sameAs: sameAs("password"),
                },
            },
        };
    },
});
